<template>
  <div id="echart-2">
    <div class="echart-2-top">
      <el-row>
        <el-col :span="4">
          <div class="row">
            <div><i class="el-icon-edit"></i></div>
            <div>
              <strong>5984.68</strong>
              <div>今日晚报</div>
            </div>
          </div>
        </el-col>
        <el-col :span="4" :offset="1">
          <div class="row">
            <div><i class="el-icon-help"></i></div>
            <div>
              <strong>29840.68</strong>
              <div>七日果实</div>
            </div>
          </div>
        </el-col>
        <el-col :span="4" :offset="1">
          <div class="row">
            <div><i class="el-icon-camera"></i></div>
            <div>
              <strong>59840.68</strong>
              <div>每日收获</div>
            </div>
          </div>
        </el-col>
        <el-col :span="9" :offset="1">
          <div class="row">
            <div><i class="el-icon-thumb"></i></div>
            <div>
              <strong>100000.00</strong>
              <div>账户余额</div>
            </div>
            <div><el-button type="success" plain>提现</el-button></div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="echart-2-buttom">
      <div class="echart-2-buttom-time">
        <el-date-picker v-model="startTime" type="date" placeholder="开始时间">
        </el-date-picker
        >&nbsp; <span>——</span>&nbsp;
        <el-date-picker v-model="endTime" type="date" placeholder="结束时间">
        </el-date-picker>
        <el-button type="primary" class="query">查询</el-button>
      </div>
      <div id="echartsContent"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      startTime: "",
      endTime: "",
    };
  },
  mounted() {
    this.getEchartsData();
  },
  methods: {
    //   获取echarts数据
    getEchartsData() {
      const ModelEchartsContent = this.$echarts.init(
        document.getElementById("echartsContent")
      );
      const option = {
        tooltip: {
          trigger: "axis",
          formatter: (params) => {
            return `${params[0].axisValue} </br><div  style=text-align:left;line-height:30px;><span style=display:inline-block;height:10px;width:10px;background:rgb(2,167,240);border-radius:10px></span>&nbsp;收益金额&nbsp;&nbsp;&nbsp;&nbsp;${params[0].value} </br>`;
          },
        },
        xAxis: {
          name: "日期",
          boundaryGap: false,
          type: "category",
          data: [
            "01/01",
            "01/02",
            "01/03",
            "01/04",
            "01/05",
            "01/06",
            "01/07",
            "01/08",
          ],
        },
        yAxis: {
          name: "金额",
          type: "value",
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260, 260],
            type: "line",
            showSymbol: false,
            label: {
              normal: {
                show: true,
                position: "top",
              },
            },
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "rgb(2,167,240)",
                },
              },
            },
          },
        ],
      };
      ModelEchartsContent.setOption(option);
      window.addEventListener("resize", function () {
        ModelEchartsContent.resize();
      });
    },
  },
};
</script>
<style scoped lang='scss'>
#echart-2 {
  width: 100%;
  padding: 10px;
  .echart-2-top {
    .row {
      padding: 10px;
      box-shadow: -1px 3px 13px 1px rgb(212, 212, 212);

      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 120px;
      div:nth-child(1) {
        text-align: right;
        width: 30%;
        i {
          width: 20px;
          height: 20px;
        }
      }
      div:nth-child(2) {
        text-align: center;
        width: 50%;
        strong {
          font-size: 24px;
          color: rgb(2, 167, 240);
        }
        div {
          width: 100%;
          margin-top: 10px;
        }
      }
    }
  }
  .echart-2-buttom {
    height: 600px;
    box-shadow: -1px 3px 13px 1px rgb(212, 212, 212);
    margin-top: 40px;
    .echart-2-buttom-time {
      padding: 20px 40px;
      span {
        color: rgb(212, 212, 212);
      }
      .query {
        margin-left: 10px;
        width: 120px;
      }
    }
    #echartsContent {
      width: 100%;
      height: 400px;
      margin-top: 20px;
    }
  }
}
</style>